import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// utils
import IsAuthorized from '../utils/isAuthorized';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
// translation
import LoadingScreen from '../components/loading-screen/LoadingScreen';
import { useLocales } from '../locales';
import { useAuthContext } from './useAuthContext';
export default function AuthorizationGuard({ children, entity, action }) {
    const { translate } = useLocales();
    const permissionString = `${entity}_${action}`;
    const { user } = useAuthContext();
    if (!user && IsAuthorized(permissionString)) {
        return _jsx(LoadingScreen, {});
    }
    if (IsAuthorized(permissionString)) {
        return _jsxs(_Fragment, { children: [" ", children, " "] });
    }
    return (_jsxs(Container, { component: MotionContainer, sx: { textAlign: 'center' }, children: [_jsx(m.div, { variants: varBounce().in, children: _jsx(Typography, { variant: "h3", paragraph: true, children: `${translate('Permission Denied')}` }) }), _jsx(m.div, { variants: varBounce().in, children: _jsx(Typography, { sx: { color: 'text.secondary' }, children: `${translate('You do not have permission to access this page')}` }) }), _jsx(m.div, { variants: varBounce().in, children: _jsx(ForbiddenIllustration, { sx: { height: 260, my: { xs: 5, sm: 10 } } }) })] }));
}
